import { setCookie, getCookie } from "./common";

var trans;

$("document").ready(function(){
    translatePage();
    initClickListeners();
   
    window.onclick = function(event) {
      if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
  }
});

function openNav(){
    document.getElementById("closeNav").style.display = "block";
    var sidePanel = document.getElementById("resTopnav");
    sidePanel.style.display = "block";
}

function initClickListeners() {
    myTopnav.querySelector('.icon').addEventListener('click', function () {
      openNav();
    });
    btnTranslate.addEventListener('click', function () {
      showDropdown();
    });
    aEn.addEventListener('click', function () {
      translatePage('en');
    });
    aEs.addEventListener('click', function () {
      translatePage('es');
    });
    document.getElementById('closeNav').addEventListener('click', function () {
      closeNav();
    });
}
function showDropdown() {
    document.getElementById("langDropdown").classList.toggle("show");
}
var language = {
    eng:{
        Chat:"Chat",
        Feat:"Features",
        Download: "Download",
        About: "About",
        idiom:'En',
        errorPage:'Uh Oh, The page you were looking for does not exist or was deleted.'
    },
    es:{
        Chat:"Chat",
        Feat:"Funciones",
        Download: "Descargar",
        About: "Sobre",
        idiom:"Es",
        errorPage:'Uh Oh, la página que estabas buscando no existe o fue eliminada.'
    }
};

  function translatePage(lang){
    var translate = false;
    if(lang){
      if(lang.includes("es"))
      {
        if (trans && trans.idiom == 'Es') {
          return;
        }
        trans = language.es;
        document.getElementsByTagName('html')[0].setAttribute('lang',"es");
        var langs = langDropdown.querySelectorAll('a');
        langs[0].className = langs[0].className.replace(" set", "");
        langs[1].className += " set";
        setCookie('lang','es',365);
      }
      else{
        if (trans && trans.idiom == 'En') {
          return;
        }
        trans = language.eng;
        document.getElementsByTagName('html')[0].setAttribute('lang',"en");
        var langs = langDropdown.querySelectorAll('a');           
        langs[0].className += " set";
        langs[1].className = langs[1].className.replace(" set", "");
        setCookie('lang','en',365);
      }
      translate = true
    }
    else if (getCookie('lang') != "") {
      if (getCookie('lang').includes('es')) {
        trans = language.es;
        document.getElementsByTagName('html')[0].setAttribute('lang',"es");
        var langs = langDropdown.querySelectorAll('a');
        langs[0].className = langs[0].className.replace(" set", "");
        langs[1].className += " set";
        translate = true
        setCookie('lang','es',365);
      } else {
        trans = language.eng;
      }
    }
    else if(navigator.language && navigator.language.includes("es")){
        trans = language.es;
        document.getElementsByTagName('html')[0].setAttribute('lang',"es");
        var langs = langDropdown.querySelectorAll('a');
        langs[0].className = langs[0].className.replace(" set", "");
        langs[1].className += " set";
        translate = true
    }
    else{
      trans = language.eng;
    }
  
    if (translate) {
      menuLang.textContent = trans.idiom;
      navChat.textContent = trans.Chat;
      navFeat.textContent = trans.Feat;
      navDownload.textContent = trans.Download;
      navAbout.textContent = trans.About;
      resChat.textContent = trans.Chat;
      resFeat.textContent = trans.Feat;
      resDownload.textContent = trans.Download;
      resAbout.textContent = trans.About;
      document.querySelector('h2').textContent =trans.errorPage;
    }
  }